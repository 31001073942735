import type { RawWidgetConfigFragment } from '../gql/fragments/__generated/RawWidgetConfig';

export default async function (): Promise<Ref<RawWidgetConfigFragment | null>> {
  // widget configuration from identifier (standalone version)
  const { widgetConfig } = await fetchWidgetConfig();

  // TODO widget configuration values from custom-element (i.e. widget integration)

  // TODO dummy widget configuration for storybook

  return widgetConfig;
}
