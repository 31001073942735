import { useQuery } from '@urql/vue';
import type { Ref } from 'vue';
import type { RawWidgetConfigFragment } from '../gql/fragments/__generated/RawWidgetConfig';
import {
  GetWidgetConfigDocument,
  type GetWidgetConfigQuery,
  type GetWidgetConfigQueryVariables,
} from '../gql/queries/__generated/GetWidgetConfig';

/** CAUTION: only internal use - do not use in components */
export default async () => {
  const { locale } = useI18n({ useScope: 'global' });
  const widgetIdentifier = useRouteParams<string>('identifier');

  const { data } = await useQuery<
    GetWidgetConfigQuery,
    GetWidgetConfigQueryVariables
  >({
    query: GetWidgetConfigDocument,
    variables: {
      identifier: widgetIdentifier,
      language: locale,
    },
  });

  const widgetConfig: Ref<RawWidgetConfigFragment | null> = computed(() => {
    return data.value?.widgetConfig ?? null;
  });

  return { widgetConfig };
};
